/* eslint-disable import/no-unused-modules */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from '../../components/common';
import SEO from '../../components/common/SEO';
import { useViewport } from '../../hooks';
import DesktopEventPage from '../../components/common/DesktopEventPage';
import MobileEventPage from '../../components/common/MobileEventPage';
import banner from '../../images/events/2024/ibc/IBC2024_events-desktop-hero.png';
import mobileBanner from '../../images/events/2024/ibc/IBC2024_events-mobile-hero.png';
import ogimage from '../../images/events/2024/ibc/IBC2024_events-og-image.png';

export default function IBC2024() {
    const { t } = useTranslation();

    const { width } = useViewport();
    const breakpoint = 500;

    return (
        <Layout title='IBC 2024 - Witbe' isEvents>
            <SEO
                title='Visit Witbe at IBC 2024 in Amsterdam: 13-16 Sep'
                ogDescription='Book a live demo today of our Ad Monitoring and Matching technology for video service providers and new AI boosted testing updates!'
                description='Book a live demo today of our Ad Monitoring and Matching technology for video service providers and new AI boosted testing updates!'
                article='article'
                image={ogimage}
            />

            {width < breakpoint ? <MobileEventPage id={13} banner={mobileBanner} /> : <DesktopEventPage id={13} banner={banner} />}
        </Layout>
    )
}
